<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>LA EN PROFF GJØRE JOBBEN</h3>
        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Tenk om du hadde en helt egen gartner som sørget for at plenen
                alltid var perfekt klippet. Oppdag fordelene ved STIHL All
                Inclusive, tjenesten vår som gjør det både enkelt og lettvint å
                eie en iMOW® robotklipper.
              </p>
              <p>
                Alt er inkludert i en fast månedspris – installasjon, service og
                vinterlagring. Velg blant flere modeller og finn den som passer
                perfekt for deg og plenen din. Deretter regnes den faste
                månedsprisen ut, og betalingen deles opp på 60 måneder. Det er
                ingen ekstra rentekostnader, startavgifter eller restverdi i
                tillegg. Prisen du betaler for finansieringsperioden er prisen
                du ville betalt for robotgressklipper, installasjon,
                vinterservice og vinterlagring.
              </p>
              <p>Finansieringen gjøres i samarbeid med Resurs Bank.</p>
            </Margins>
          </b-col>

          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>

        <Separator hasLine size="medium" />

        <h3>ALT INKLUDERT</h3>

        <b-list-group>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlInstallationSVG class="icon icon--medium mr-2" />
            INSTALLASJONSHJELP*
          </b-list-group-item>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlServiceSVG class="icon icon--medium fill-white mr-2" />
            OPPFØLGINGSBESØK MED FORHANDLEREN DIN
          </b-list-group-item>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlWinterStorageSVG class="icon icon--medium fill-white mr-2" />
            SERVICE OG VINTERLAGRING I 4 ÅR
          </b-list-group-item>
        </b-list-group>

        <div class="margins__triple text-left">
          <Button
            @click="$router.push({ name: ROUTES.SELECT_BUNDLE.name })"
            border="none"
            variant="inverse-primary"
          >
            {{ $t('HEADER.HOME') }}
          </Button>
        </div>

        <p class="margins__triple">
          <small class="text-extra-small">
            *Fri standardinstallasjon innen en radius på 20 km fra forhandlerens
            butikk. For strekninger over 20 km og installasjonssteder uten
            landsforbindelse påløper en ekstra kjørekostnad. Fergeavgift kan
            også påløpe. En standardinstallasjon omfatter opptil 4 øyer (bed
            eller annet som roboten skal kjøre rundt og som må sperres av med
            avgrensningstråd.) For ytterligere forespørsler, som for hager med
            mange bed, gangveier eller busker, må en eventuell tilleggsavgift
            avtales direkte med forhandleren.
          </small>
        </p>

        <Separator size="medium" hasLine />

        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <h3>BEHOLD ELLER OPPGRADER</h3>
              <p>
                Din STIHL All Inclusive-avtale omfatter en årlig service og
                vinterlagring i 4 år fra avtalestart. Betalingen deles opp på
                totalt 60 måneder. Etter 4 år kan du enten velge å fornye
                avtalen og få en ny iMOW®, eller beholde maskinen du har.
              </p>
              <p>
                Hvis du velger å fornye avtalen, fortsetter finansieringen med
                samme vilkår som tidligere. Restverdien fra tidligere avtale
                avregnes mot din nye robotklippers verdi. Eventuell
                verdiforskjell blir lagt til i den nye finansieringsavtalen. Den
                gamle modellen vil bli kjøpt tilbake til den restverdien som ble
                fastsatt da avtalen ble inngått, såfremt ikke maskinen er
                vanskjøttet på en slik måte at det i vesentlig grad påvirker
                robotgressklipperens verdi negativt. Normal slitasje betraktes
                ikke som vanskjøtsel, og den årlige vinterservicen bidrar til å
                forebygge skader.
              </p>
              <p>
                Når din gamle maskin er avregnet, vil den bli gjenbrukt og holde
                gresset grønt utenfor Norges grenser.
              </p>
              <p>
                Hvis du velger å ikke fornye avtalen etter 4 år, kan du beholde
                maskinen som din egen. Betalingen fortsetter til det har gått 60
                måneder fra avtalestart, siden den da er ferdig betalt.
              </p>
            </Margins>
          </b-col>
          <b-col cols="6" xl="3">
            <EkoWorldSVG class="icon icon--extra-extra-large" />
          </b-col>
        </b-row>

        <Separator size="medium" hasLine />

        <h3>HVILKE MODELLER KAN JEG VELGE MELLOM?</h3>
        <p>
          Du kan tegne en STIHL All Inclusive-avtale for alle våre
          iMOW®-modeller. Dermed kan du skaffe deg en robotgressklipper som
          passer til akkurat dine behov - uansett om gressplenen din er 800 m²
          eller 5 000 m². Forhandleren din er alltid tilgjengelig for å hjelpe
          deg med råd og veiledning, og sammen finner dere det perfekte
          alternativet. Du kan lese mer om de ulike iMOW®-modellene på
          <a
            href="https://www.imow.no"
            target="_blank"
            rel="noopener noreferrer"
            >www.imow.no</a
          >
        </p>

        <Separator size="medium" hasLine />

        <h3>iMOW® ER BEST-I-TEST</h3>

        <p>
          I løpet av årene har iMOW® plassert seg øverst på pallen i flere ulike
          tester. Da 35 testpersoner rundt omkring i Norden fikk teste og
          vurdere iMOW® i en måneds tid, valgte 100 % å anbefale produktet.
        </p>

        <Separator size="medium" hasLine />

        <h3>4 ENKLE TRINN TIL EN IMPONERENDE GRESSPLEN</h3>

        <List :items="listItems" variant="numbers" />

        <p class="margins__triple">
          <small class="text-extra-small">
            *Fri installasjon innen en radius på 20 km fra forhandlerens butikk.
            For strekninger over 20 km og installasjonssteder uten
            landsforbindelse påløper en ekstra kjørekostnad. Fergeavgift kan
            også påløpe.
          </small>
        </p>

        <Separator size="medium" hasLine />

        <b-embed
          v-if="hasTargetingConsentLevel"
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/6cpgdd8SpCI"
          allowfullscreen
        ></b-embed>

        <Separator size="medium" hasLine />

        <h3>{{ $t('RETURN_CHECKLIST') }}</h3>

        <List
          :items="returnChecklist"
          variant="numbers"
          class="margins__double"
        />

        <h5 class="margins__triple">{{ $t('RETURN_ITEMS') }}</h5>

        <List :items="returnItems" variant="bullets" />
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Button, Container, List, Margins, Separator } from '@/components';
import StihlInstallationSVG from '@/assets/images/stihl-installation.svg';
import StihlServiceSVG from '@/assets/images/stihl-service.svg';
import StihlWinterStorageSVG from '@/assets/images/stihl-winter-storage.svg';
import EkoWorldSVG from '@/assets/images/eko_world.svg';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Button,
    Margins,
    List,
    StihlInstallationSVG,
    StihlServiceSVG,
    StihlWinterStorageSVG,
    EkoWorldSVG,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Kontakt din nærmeste <a href="https://stihlallinclusive.no/select-store">STIHL forhandler</a> og velg den modellen som passer best for deg.',
        'Forhandleren gjennomgår og installerer din nye iMOW® i hagen din. *',
        'Du betaler en fast månedspris. Service og vinterlagring i 4 år er inkludert.',
        'Etter 4 år kan du enten velge å fornye avtalen og få en ny iMOW®, eller du kan beholde den du har som din egen.',
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
